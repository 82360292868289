import React from 'react';
import {graphql,useStaticQuery}from 'gatsby';
import Img from 'gatsby-image';




const Logo = () => {
    
    const {image} = useStaticQuery(graphql`
    query{
        image: file(relativePath: {eq:"Logo.png"}){
          sharp:childImageSharp{
            fixed(width: 98){
                ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `)
   
    return ( 
        <Img fixed={image.sharp.fixed} alt='logo'></Img>
     );


}
 
export default Logo;
