import React from 'react';
import Header from './header/header';
import { Global, css } from '@emotion/core';
import Helmet from 'react-helmet';
import SocialNetwork from './socialNetwork';
import Footer from './footer/footer';
import 'typeface-montserrat';
import 'typeface-baskervville';
import 'normalize.css';


const Layout = ({ children }) => {
    return ( 
        <>
            <Global styles={css`
            html{
                font-size: 62.5%;
                box-sizing:border-box;
                line-height: 1.15; 
                -webkit-text-size-adjust: 100%; 
              }
              *,*:before,*:after{
                box-sizing:inherit;
              }
              body{
                font-size:18px;
                font-size:1.8rem;
                line-height: 1.5;
                font-family:'Montserrat', sans-serif;
                background-color:#f9f7f7;
              }
              h1,h2,h3{
                margin:0;
                line-height:1.5;
              }
              h1,h2,h4{
                font-weight:bold;
                font-family:'Baskervville', serif;
              }
              h3{
                font-family: 'Baskervville' ,sans-serif;
              }
             
              h2{
                font-size:2.5rem;
              }
              ul{
                list-style:none;
                margin:0;
                padding:0;
              }
              a{
                text-decoration:none;
              }

        
            `}/>
            <Helmet>
              <html lang='es'/>
              
             
              <meta charSet="utf-8" />
              <meta name="Description" content="Estudio Contable Lemberger se encuentra ubicado en la ciudad de Mar del Plata, desempeñando durante años el asesoramiento profesional y consultoría profesional e integral a una amplia gama de clientes.

Entre nuestros valores se destacan la Responsabilidad, Compromiso, Respecto, Eficiencia, Calidad y Ética Profesional, siendo estos los que definen la esencia de nuestro Estudio.

La característica principal que poseemos es brindar un servicio altamente calificado y personalizado que se adapta a las necesidades de cada cliente complementándolo para maximizar sus fortalezas y trabajar sobre sus debilidades. De esta manera trabajamos de forma conjunta para lograr el progreso del negocio.
                                Estudio Contable Lemberger - Contabilidad - Societaria - Mar del Plata - Contador Publico - Estudio Lemberger"/>
              <title>Estudio Contable Lemberger</title>
              


              
            </Helmet>
                
                
        <Header />
        <SocialNetwork/>

                {children}
        <Footer/>
        </>

      );
}
 
export default Layout;