import React from 'react';
import styled from '@emotion/styled';

const Social=styled.div`
    position:fixed;
    right:0px;
    top:130px;
    z-index:100000000;
    font-size:3.5rem;
    display:flex;
    flex-direction:column;
    align-items: flex-end;

    @media(max-width:768px){
        top:170px;
        font-size:2.5rem;
    }
   
    
    .icon{
            color:white;
            padding:.6rem;
            padding-left:14px;
            margin-right:0px;
            display:flex;
            text-decoration:none;
            transition: all .5s;

            :first-of-type{
                border-radius: 1rem 0 0 0;
            }
            :last-child{
                border-radius: 0 0 0 1rem;
            }
        }
            
            

        .icon-facebook{
            background-color:#3B5998;
            
            :hover{
                padding-right:35px;
                border-radius: 1rem 0 0 1rem;
            }
            
        }

        .icon-instagram{
            background: #f09433; 
            background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
            background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
            :hover{
                padding-right:35px;
                border-radius: 1rem 0 0 1rem;
            }
        }
        .icon-whatsapp{
            background-color: #4FCE5D;
            
            :hover{
                padding-right:35px;
                border-radius: 1rem 0 0 1rem;
            }
        }
 
        
    
`

const SocialNetwork = () => {
    return ( 
        <Social>
            
                <a href='https://www.facebook.com/estudiocontablelemberger' target="_blank" rel="noopener noreferrer" className='icon icon-facebook'> </a>
                <a href='https://www.instagram.com/estudiocontablelemberger/' target="_blank" rel="noopener noreferrer"className='icon icon-instagram'> </a>
                <a href='https://api.whatsapp.com/send?phone=+5492233069641&text=Hola%20Estudio%20Contable%20Lemberger,%20necesito%20consultar%20sobre..' target="_blank" rel="noopener noreferrer"  className='icon icon-whatsapp'> </a>
        
        </Social>
            
            
            
     );
}
 
export default SocialNetwork;