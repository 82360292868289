import React from 'react';
import styled from '@emotion/styled'
import {Link} from 'gatsby';


const Menu = styled.ul`
    display:flex;
    
    justify-content:center;
    padding-bottom:2rem;
    
    @media (min-width: 768px){
        padding-bottom:0;
     
    }
    @media (max-width:480px){
        margin-top:15px;
        
    }
    @media (max-width:768px){
        margin-bottom:1rem;
        
    }
`  



const NavLink =styled(Link)`
    color:black;
    font-size:1.7rem;
    font-weight:bold; 
    line-height:1;
    
    text-decoration:none;
    padding:1rem;
    margin-right:1rem;
    &:last-of-type{
        margin-right:0;
    }
    &.pagina-actual{
        border-bottom:2px solid #3f72af;
        font-weight: bold;
        color:#112D4E;
    }
    
    `
   

const Nav = () => {
    return (  
        <Menu>
            <li><NavLink to={"/"} activeClassName='pagina-actual'>Inicio </NavLink> </li>
            <li><NavLink to={"/servicios"} activeClassName='pagina-actual'>Servicios </NavLink> </li>
            <li><NavLink to={"/contact"} activeClassName='pagina-actual'>Contacto </NavLink> </li> 
        </Menu>
    );
}
 
export default Nav;