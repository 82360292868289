import React from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/core';
import {Link} from 'gatsby';



        
    

const Servicios = styled.div`
        color:white;
        padding:2rem;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        font-size:1.7rem;
        padding:2rem;
        @media (max-width:480px){
            padding:0rem;
            margin:2rem 0;
            
        }
        h3{
            font-size:2.8rem;
            text-align:center;
            @media (max-width:480px){
                font-size:2rem;
            }
        }
        .first{
            margin:2rem 0;
            font-size:2.2rem;
            @media (max-widht:480px){
                font-size:1.6rem;
            }
        }
        p{
            margin:1.1rem 0;
            transition:all .5s;
            :hover{
                color:#3f72af;
            }
            @media (max-width:1024px){
                font-size:1.5rem;
            }
            @media (max-width:480px){
                font-size:1.6rem;
            }
        }
        a{
            color:white;
            margin:1.1rem 0;
            transition:all .5s;
            :hover{
                color:#3f72af;
            }
            @media (max-width:1024px){
                font-size:1.5rem;
            }
           
            
        }
        span{
            font-size:5rem;
            margin-bottom:2rem;
        }
        
`


const Footer = () => {
    const year = new Date().getFullYear()

    return ( 
        <section css={css`
            background-color:#112d4e;
        `}>
            <div css={css`
                max-width:1200px;
                display:flex;
                margin:100px auto 20px auto;
                justify-content:space-between;
                align-items:flex-start;
                flex-direction:row;
                padding:2rem;
                @media (max-width:768px){
                    flex-direction:column;
                    justify-content:center;
                    align-items:center;
                    
                }
            `}>
                <Servicios>
                    <span className="icon-stats-dots"></span>
                    <p className='first'>Conoce Nuestros Servicios!</p>
                    <Link to={'/servicios'}>Impositivo</Link>
                    <Link to={'/servicios'}>Contable Administrativo</Link>
                    <Link to={'/servicios'}>Laboral y previsional</Link>
                    <Link to={'/servicios'}>Nuevos Negocios (START UPS)</Link>
                   
                    
                    
                </Servicios>
                <Servicios>
                    <span className="icon-mail2"></span>
                        <p className='first'>Escribinos !</p>

                        <p>Envíe su consulta a nuestro correo</p>

                        <p><a href='mailto:info@estudiolemberger.com.ar'>info@estudiolemberger.com.ar</a></p>    
                </Servicios>

                <Servicios>
                        <span className="icon-mobile"></span>
                        <p className='first'>Comunicate con nosotros</p>
                        <p> Cel:<a href='tel://+54 9 2233 06-9641'> 223-3069641</a></p>
                </Servicios>
                
             
            </div>
            <div css={css`
                max-width:1200px;
                margin:10px auto 0 auto;
                padding:1rem;
                text-align:center;
                border-top:1px solid #f9f7f7;
                color:white;
                @media (max-width:480px){
                font-size:1.2rem;
            }
            `}
            >
                <p>Estudio Lemberger {year} &copy; Todos los derechos reservados</p><p>Powered by: Aguilera WebDeveloper</p> </div>
        </section>
     );
}
 
export default Footer;
