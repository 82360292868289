import React from 'react';
import styled from '@emotion/styled';
import {css} from '@emotion/core';
import Logo from './logo';

import Nav from './nav';


const Heading = styled.header`
    background-color:#dbe2ef;
    padding:1rem;
    position:fixed;
    left:0;
    top:0;
    z-index:10000;
    width:100%;
    
    
    @media (max-width:768px){
        padding:0.4rem;
    }
`  

const Header = () => {
    return (  
        <Heading>
            
            <div css={css`
                display:flex;
                align-items:center;
                justify-content:space-evenly;
                max-width:2200px;
                width:100%;
                margin:0 auto;
                @media (max-width:768px){
                        
                        flex-direction:column;
                        width:100%;
                    }
  
            `}>
                <div css={css`
                    display:flex;
                    align-items:center;
                    justify-content:space-between;
                    @media (max-width:768px){
                            justify-content:center;
                        }
                `}>
                    <div css={css`
                        @media (max-width:480px){
                            margin-left:1.5rem;
                        }
                    `}>
                    <Logo css={css`
                        @media (max-width:480px){
                           width:160px;
                           
                        }
                    `}/>
                    </div>
                
                    <h2 css={css`
                         text-align:center;
                         @media (min-width: 460px){
                             padding-left: 1.5rem;
                         }
                    ` }>Estudio Contable Lemberger</h2>
                </div>
                <div>
                    <Nav/>
                </div>
            </div>
                      
        </Heading>
    );
}
 
export default Header;